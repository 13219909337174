<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="$store.state.app.windowWidth > 992"
          fluid
          style="width: 240px; max-height: 60px"
          :src="imgLogo"
          alt="Logo"
        />
        <b-img
          v-else
          fluid
          style="width: 150px"
          :src="imgLogoSmall"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-navbar-nav class="nav align-items-end">
            <locale />
          </b-navbar-nav>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ t("Welcome to 3Digital!") }}👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ t("Please sign-in") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
              autocomplete="off"
            >
              <!-- email -->
              <b-form-group>
                <label for="login-email">{{ t("Email") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    autocomplete="on"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ t("Password") }}</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small style="color: #e6354a"
                      >{{ t("Forgot Password") }}?</small
                    >
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ t("Remember Me") }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isSignInClicked"
              >
                {{ t("Sign in") }}
              </b-button>
              <div v-if="isSignInClicked" class="spinner-block"><b-spinner variant="primary" /></div>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ t("New on our platform") }}?</span>
            <b-link :to="{ name: 'auth-register' }">
              <span style="color: #e6354a"
                >&nbsp;{{ t("Create an account") }}</span
              >
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser, loginUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Locale,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { t } = useI18nUtils();
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      imgLogo: require("@/assets/images/logo/New_Project_1.png"),
      imgLogoSmall: require("@/assets/images/logo/New_Project_2.png"),
      signInClicked: false,

      // validation rules
      required,
      email,
      t,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    profileData() {
      return this.$store.state.Profile.profileData;
    },
    isSignInClicked() {
      return this.signInClicked
    }
  },
  methods: {
    login() {
      this.signInClicked = true
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          loginUser(this.userEmail, this.password)
            .then(() => {
              const userDataAbility = {
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
              };
              localStorage.setItem(
                "userDataAbility",
                JSON.stringify(userDataAbility)
              );
              this.$ability.update(userDataAbility.ability);
              this.me();
            })
            .catch((err) => {
              this.signInClicked = false
              this.$refs.loginForm.errors.email.push(
                "Email or Password is Invalid"
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: err.response.data?.detail
                    ? err.response.data?.detail
                    : "Please try again",
                },
              });
            });
        }
      });
    },
    me() {
      store.dispatch("UserData/GET_USER_DATA").then((res) => {
        this.$router.replace(getHomeRouteForLoggedInUser("admin"));
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Welcome ${res.data.first_name} ${res.data.last_name}`,
            icon: "CoffeeIcon",
            variant: "success",
            text: "You have successfully logged in. Now you can start to explore!",
          },
        });
        store.dispatch("Profile/GET_MY_PROFILE");
        store.dispatch("Profile/amIAdmin");
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.spinner-block {
  position: absolute;
  margin-top: -32px;
  right: 30px;
  height: 0px;
}
</style>
